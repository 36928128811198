<template>
    <div>
        <div class="text-center">
            <div style="font-size: 16px;"><strong>Distribución de %</strong></div>
        </div>        
        <div style="width: 100%; height: 300px;" ref="donoutChart">                            
        </div>
    </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
// import ws from '@/services/graphs';

export default {
    name: 'DonoutSemaforo',
    props: {
        permissions: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    components : {
	},
    data: function () {
		return {
            items: {},
            root: "",
        }
    },
    computed: {
        
    },
    methods: {
        loadGraph: async function(data_response){
            if (this.root) {
                this.root.dispose();
            }
            this.root = am5.Root.new(this.$refs.donoutChart);        
            this.root.setThemes([am5themes_Animated.new(this.root)]);

            // Se inicializa el contenedor (al parecer puede contener varias graficas este mismo contenedor NO PROBADO)
            let chart = this.root.container.children.push(
                // Se inicializa el tipo de grafica
                am5percent.PieChart.new(this.root, {
                    layout: this.root.verticalLayout,
                    innerRadius: am5.percent(50)
                })
            );


            // Creamos la serie (cada serie seria cada columna)
            let serie1 = chart.series.push(am5percent.PieSeries.new(this.root, {
                    name: "Series1",
                    valueField: "total",
                    categoryField: "service",
                    legendLabelText: "{category}[/]",
                }));        

            serie1.labels.template.set("forceHidden", true);
            serie1.ticks.template.set("forceHidden", true);

            //Si se tiene que repetir
            serie1.data.setAll(data_response);
            //relleno
            serie1.slices.template.adapters.add("fill", function(fill, target) {
                let context = target.dataItem.dataContext;
                switch (context.code) {
                    case "success":
                        return am5.color(0x1B9E3E);
                    case "warning":
                        return am5.color(0xF9B115)
                    case "poor":
                        return am5.color(0xE55353)
                    case "dark":
                        return am5.color(0x6b7785)                                           
                    default:
                        return am5.color(0x6b7785)
                }                                   
            });
            //Orillas
            serie1.slices.template.adapters.add("stroke", function(stroke, target) {
                let context = target.dataItem.dataContext;
                switch (context.code) {
                    case "success":
                        return am5.color(0x1B9E3E);
                    case "warning":
                        return am5.color(0xF9B115)
                    case "poor":
                        return am5.color(0xE55353)
                    case "dark":
                        return am5.color(0x6b7785)                                           
                    default:
                        return am5.color(0x6b7785)
                }                                   
            });

            serie1.data.setAll(data_response);

            let legend = chart.children.push( 
                    am5.Legend.new(this.root, {
                        y: am5.percent(85),
                    })
                );
            legend.data.setAll(serie1.dataItems);
            serie1.appear(1000, 100);
        },
    },
  	mounted: async function(){        
    },
    beforeDestroy() {
        if (this.root) {
        this.root.dispose();
        }
  }
}

</script>
